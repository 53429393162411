import {useEffect, useReducer, useMemo, useRef} from "react"

const awsS3Config = {
  keyStartsWith: 'nt9b30yq/',
  awsaccesskeyid: 'AKIAQ2ZTVN6TAR4CZ7CV',
  acl: 'public-read',
  policy: 'CnsgImV4cGlyYXRpb24iOiAiMjAzMy0xMi0wMVQxMjowMDowMC4wMDBaIiwgCiAiY29uZGl0aW9ucyI6IFsgCiB7ImJ1Y2tldCI6ICJnMHl1MGw0cHhqIiB9LCAKIFsic3RhcnRzLXdpdGgiLCAiJGtleSIsICJudDliMzB5cS8iXSwgCiBbInN0YXJ0cy13aXRoIiwiJENvbnRlbnQtVHlwZSIsIiJdLCAKIHsiYWNsIjogInB1YmxpYy1yZWFkIiB9CiAgXQogfQo=',
  signature: 'raRJMhwoeoyngkxq6RX1hkndlmg=',
  host: 'https://g0yu0l4pxj.s3.amazonaws.com/'
}

const getXHR = (formData, host, {onprogress, onload, onerror, onabort}) => {
  const xhr = new XMLHttpRequest()

  let checker
  let checked = 0
  let loaded = 0
  let percent = 0

  xhr.upload.onprogress = e => {
    if (e.lengthComputable) {
      loaded = e.loaded
      const currentPercent = Math.round(100 * e.loaded / e.total)
      if (currentPercent > percent) {
        onprogress(currentPercent)
        percent = currentPercent
      }
    }
  }

  xhr.onload = (e) => {
    clearInterval(checker)
    onload(e)
  }

  xhr.onerror = onerror

  const start = () => {
    xhr.open('POST', host)
    xhr.send(formData)
    onprogress(percent)

    checker = setInterval(() => {
      if (loaded === checked) {
        clearInterval(checker)
        xhr.abort()
        start()
      }
      checked = loaded
    }, 5000)
  }

  const abort = () => {
    xhr.abort()
    onabort()
  }

  return {start, abort}
}

const cropUnsafeSymbols = (string) => {
  const regexp = /[^0-9a-zA-Z!\-_.*'()]/g
  return string.replace(regexp, '').replace(/ +/g, ' ')
}

const recursiveDecodeURIComponent = (uriComponent) => {
  try {
    const decodedURIComponent = decodeURIComponent(uriComponent)
    if (decodedURIComponent === uriComponent) {
      return cropUnsafeSymbols(decodedURIComponent)
    }
    return recursiveDecodeURIComponent(decodedURIComponent)
  } catch (e) {
    return cropUnsafeSymbols(uriComponent)
  }
}

const getAwsUploader = (id, file, config, {onprogress, onload, onerror, onabort}) => {
  const key = `${config.keyStartsWith}${id}/${recursiveDecodeURIComponent(file.name)}`
  const formData = new FormData()
  formData.append('key', key)
  formData.append('AWSAccessKeyId', config.awsaccesskeyid)
  formData.append('acl', config.acl)
  formData.append('policy', config.policy)
  formData.append('signature', config.signature)
  formData.append('Content-Type', file.type)
  formData.append('file', file)
  const _onload = () => onload(`${config.host}${key}`)
  const xhr = getXHR(formData, config.host, {onprogress, onload: _onload, onerror, onabort})
  return xhr
}

const noFileUpload = {isFetched: undefined, refs: {}}

const reducer = (state, action) => ({...state, [action.id]: {...state[action.id], [action.key]: action.value}})

const initFileUpload = files => files.reduce((result, {id}) => ({
  ...result,
  [id]: {
    progress: undefined,
    error: undefined,
    url: undefined
  }
}), {})

const useFileUpload = (files) => {
  const [state, dispatch] = useReducer(reducer, files, initFileUpload)
  const xhr = useRef(files.reduce((result, {id, file}) => {
    if (!file) return result
    result[id] = getAwsUploader(id, file, awsS3Config, {
      onprogress: value => dispatch({id, key: 'progress', value}),
      onload: value => dispatch({id, key: 'url', value}),
      onerror: value => dispatch({id, key: 'error', value}),
      onabort: _ => dispatch({id, key: 'abort', value: true})
    })
    return result
  }, {})).current

  const queue = useMemo(() => Object.keys(xhr).map(i => xhr[i]), [xhr])

  useEffect(() => {
    queue.forEach(q => q.start())
  }, [queue, xhr])

  useEffect(() => () => {
      queue.forEach(q => q.abort())
    },
    // eslint-disable-next-line
    []
  )

  const countUpload = files.filter(({id}) => state[id].url || state[id].abort).length
  const isFetched = files.length === countUpload
  const refs = files.reduce((result, {id}) => ({...result, [id]: {...state[id], xhr: xhr[id]}}), {})
  return {isFetched, refs}
}

const useSendTextDraft = (save, remove, id, data, available) => {
  const {message, from, time} = data
  useEffect(() => {
      if (!available) return
      save({id, message, from, time})
    },
    // eslint-disable-next-line
    [available, save]
  )
  return noFileUpload
}

const useSendFilesDraft = (save, remove, id, data, available) => {
  const fileUpload = useFileUpload(data.files)
  useEffect(() => {
      if (!available) return
      if (!fileUpload.isFetched) return

      const files = data.files
        .filter(file => !fileUpload.refs[file.id].abort)
        .map(file => ({
          ...file,
          url: fileUpload.refs[file.id].url
        }))

      if (files.length) save({id, ...data, files})
      else remove()

    },
    // eslint-disable-next-line
    [available, fileUpload.isFetched, save]
  )
  return fileUpload
}

export {useSendTextDraft, useSendFilesDraft}
